@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');

@layer base {
	@font-face {
		font-family: 'collegiomazza';
		src: local('collegiomazza'), url('/public/fonts/mazza2020.woff') format("woff");
		/* font-weight: bold; */
	}
	/* body{
      font-family: 'collegiomazza'
    } */
}

.form-label.required:after {
	content: ' * ';
	color: red;
}

.react-datepicker__input-container {
	position: static !important;
}
@layer components {
	.form-input {
		@apply hover:border-bluemazza hover:shadow-lg focus:outline-none focus:shadow-xl focus:border-bluemazza transition-all border rounded w-full py-2 px-3 text-gray-700 leading-tight shadow appearance-none cursor-pointer placeholder-gray-400;
	}

	.form-input.error {
		@apply focus:border-red-600 hover:border-red-600 border-red-500;
	}

	/*	.form-label.required {
		@apply after:content-[' * '] after:text-red-500;
	} */

	input.auth-input {
		@apply focus:shadow-xl focus:border-white hover:border-white hover:shadow-lg transition-all border rounded w-full py-2 px-3 text-gray-700 leading-tight shadow appearance-none cursor-pointer placeholder-gray-400;
	}

	label.form-label {
		@apply block text-lightbluemazza text-sm font-bold mb-2;
	}

	.form-title {
		@apply text-bluemazza font-bold text-lg mb-4;
	}

	.input-error {
		@apply text-red-500 my-2 text-xs font-bold h-px;
	}

	.input-error.clear {
		@apply text-yellow-400;
	}

	.input-warning {
		@apply text-orange-500 mt-3 text-xs font-bold underline;
	}

	.bluemazza-button {
		@apply disabled:bg-blue-500 disabled:text-white disabled:opacity-50 border border-bluemazza focus:outline-none transition-colors ease-in-out duration-300 bg-blue-500 hover:text-bluemazza hover:bg-white text-white font-medium py-2 px-4 rounded;
	}

	.bluemazza-button.outline {
		@apply disabled:bg-blue-500 disabled:text-white outline-none disabled:opacity-50 focus:outline-none border border-bluemazza transition-colors ease-in-out duration-300 bg-white hover:bg-blue-500 hover:text-white text-black font-medium py-2 px-4 rounded;
	}

	.bluemazza-submit-button {
		@apply h-14 md:h-auto fixed md:static bottom-0 left-0 w-full md:w-auto disabled:bg-slate-200 disabled:text-black md:disabled:bg-blue-500 md:disabled:text-white md:disabled:opacity-50 border border-bluemazza focus:outline-none transition-colors ease-in-out duration-300 bg-blue-500 hover:text-bluemazza hover:bg-white text-white font-medium py-2 px-4 rounded;
	}

	.red-button {
		@apply focus:outline-none border-x-slate-100 transition-colors ease-in-out duration-300 bg-red-500 hover:bg-red-700 text-white font-medium py-2 px-4 rounded;
	}

	.dropzone {
		@apply transition-colors ease-in-out duration-300 flex flex-col items-center p-4 border border-bluemazza rounded bg-slate-200 hover:bg-bluemazza hover:text-white;
	}
}
